@layer components {
  /* Button Containers */
  .btn-container {
    @apply -mt-9 
      flex 
      items-center 
      justify-center 
      flex-wrap
      -ml-5;
  }

  .btn-container .btn-wrapper {
    @apply mt-9
      pl-5;
  }

  /* Button Triangle */
  .btn,
  .btn-triangled {
    @apply relative
      inline-block
      text-center
      leading-none
      px-30px
      py-3
      text-xs
      font-noto
      font-semibold
      cursor-pointer
      outline-none
      uppercase
      z-0
      transition-all
      text-black
      duration-300;
  }

  .btn-triangled:before {
    @apply absolute
      inset-0
      -z-1;
    content: "";
    -webkit-clip-path: polygon(
      17px 0,
      100% 0,
      100% 100%,
      0 100%,
      0 0,
      4px 0,
      10px 10px
    );
    clip-path: polygon(
      17px 0,
      100% 0,
      100% 100%,
      0 100%,
      0 0,
      4px 0,
      10px 10px
    );
  }

  .btn-triangled .triangle {
    @apply absolute
      left-2px
      -top-2
      transition-all
      duration-300;
  }

  .btn-triangled:hover .triangle {
    @apply -top-1px;
  }

  /** BUTTONS WITH ICONS **/
  .btn-icon svg {
    @apply text-red-500;
  }

  .btn-icon:hover svg {
    @apply text-yellow-700;
  }

  /** PRIMARY BUTTONS **/
  /* Yellow Button */
  .btn-yellow {
    @apply bg-yellow-700;
  }

  .btn-yellow:hover {
    @apply bg-neon-red-400
      text-white;
  }

  .btn-triangled.btn-yellow {
    @apply bg-transparent;
  }

  .btn-triangled.btn-yellow:before {
    @apply bg-yellow-700;
  }

  .btn-triangled.btn-yellow:hover:before {
    @apply bg-neon-red-400;
  }

  .btn-triangled.btn-yellow .triangle {
    @apply text-pink-500;
  }

  .btn-triangled.btn-yellow:hover .triangle {
    @apply text-yellow-700;
  }

  /* Red Button */
  .btn-red {
    @apply bg-neon-red-400
      text-white;
  }

  .btn-red:hover {
    @apply bg-yellow-700
      text-black;
  }

  .btn-triangled.btn-red {
    @apply bg-transparent;
  }

  .btn-triangled.btn-red:before {
    @apply bg-neon-red-400;
  }

  .btn-triangled.btn-red:hover:before {
    @apply bg-yellow-700;
  }

  .btn-triangled.btn-red .triangle {
    @apply text-yellow-700;
  }

  .btn-triangled.btn-red:hover .triangle {
    @apply text-pink-500;
  }

  /* Disabled Button */
  .btn-disabled {
    @apply bg-gray-200
      text-black;
  }

  .btn-triangled.btn-disabled {
    @apply bg-transparent;
  }

  .btn-triangled.btn-disabled:before {
    @apply bg-gray-300;
  }

  .btn-triangled.btn-disabled .triangle {
    @apply text-gray-200;
  }

  /** SECONDARY BUTTONS **/
  /* Yellow Border Button */
  .btn-yellow-border {
    @apply bg-white
      bg-opacity-40
      border-2px
      border-yellow-700;
  }

  .btn-yellow-border:hover {
    @apply bg-white
      text-black
      bg-opacity-40
      border-2px
      border-neon-red-400;
  }

  .btn-triangled.btn-yellow-border {
    @apply bg-transparent
      border-none;
  }

  .btn-triangled.btn-yellow-border:before {
    @apply bg-white
        bg-opacity-40
        border-2px
        border-yellow-700;
  }

  .btn-triangled.btn-yellow-border:hover:before {
    @apply bg-white
        bg-opacity-40
        border-2px
        border-neon-red-400;
  }

  .btn-triangled.btn-yellow-border .triangle {
    @apply text-pink-500;
  }

  .btn-triangled.btn-yellow-border:hover .triangle {
    @apply text-yellow-700;
  }

  /* Black Border Button */
  .btn-black-border {
    @apply bg-white
      bg-opacity-70
      border-2px
      border-black;
  }

  .btn-black-border:hover {
    @apply bg-white
      bg-opacity-40
      border-2px
      border-yellow-700;
  }
  .btn-ocean-border {
    @apply bg-white
      bg-opacity-70
      border-2px
      border-neutro-ocean-300;
  }

  .btn-ocean-border:hover {
    @apply bg-white
      bg-opacity-40
      border-2px
      text-pink-500
      border-pink-500;
  }

  .btn-triangled.btn-black-border {
    @apply bg-transparent
      border-none;
  }

  .btn-triangled.btn-black-border:before {
    @apply bg-white
        bg-opacity-70
        border-2px
        border-black;
  }

  .btn-triangled.btn-black-border:hover:before {
    @apply bg-white
        bg-opacity-40
        border-2px
        border-yellow-700;
  }

  .btn-triangled.btn-black-border .triangle {
    @apply text-yellow-700;
  }

  .btn-triangled.btn-black-border:hover .triangle {
    @apply text-pink-500;
  }

  /* Text Link CTA */
  .text-link {
    @apply text-sm
      leading-none
      font-semibold
      normal-case
      transition-all
      duration-300
      border-transparent;
  }
  .text-link:hover {
    @apply text-neon-red-400
      border-neon-red-500;
  }

  .text-link.black {
    @apply text-black
      border-transparent;
  }
  .text-link.black:hover {
    @apply text-neon-red-400
      border-neon-red-400;
  }

  .text-link.blue {
    @apply text-blue-800
      border-transparent;
  }
  .text-link.blue:hover {
    @apply text-neon-red-400
      border-neon-red-400;
  }

  .text-link.red {
    @apply text-neon-red-400
      border-transparent;
  }
  .text-link.blue:hover {
    @apply text-neon-red-400
      border-neon-red-400;
  }

  .text-link.red-neon {
    @apply text-neon-red-400
      border-transparent;
  }
  .text-link.red-neon:hover {
    @apply text-blue-800
      border-blue-800;
  }

  .text-link.pink {
    @apply text-pink-500
      border-transparent;
  }
  .text-link.pink:hover {
    @apply text-blue-800
      border-blue-800;
  }

  .text-link.pink-neon {
    @apply text-neon-red-400
      border-transparent;
  }
  .text-link.pink-neon:hover {
    @apply text-blue-800
      border-blue-800;
  }

  p a,
  .text-link {
    @apply text-black
      inline-block
      font-noto
      font-semibold
      no-underline
      cursor-pointer
      border-b
      border-black;
  }

  /* Order Online Scroll Link */
  .menu-active-link {
    @apply text-black
      text-base
      font-noto;
  }

  /* Facebook button Login */
  .facebook-button {
    @apply bg-facebook
      text-white
      font-noto
      px-2
      py-2;
  }

  .products-pagination a {
    @apply block
      outline-none;
  }
}
