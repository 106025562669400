@layer components {
  .loader-ft {
    width: 120px;
    aspect-ratio: 1;
    background: 
      linear-gradient(45deg,#EB3F55 50%,#0000 0),
      linear-gradient(45deg,#0000 50%,#EB3F55 0),
      linear-gradient(-45deg,#00AFAA 50%,#0000 0),
      linear-gradient(-45deg,#0000 50%,#00AFAA 0),
      linear-gradient(#FFDE00 0 0);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    animation: l18 1.5s infinite;
  }
  @keyframes l18{
    0%   {background-position:50% 50%,50% 50%,50%  50% ,50% 50%,50% 50%}
    25%  {background-position:0  100%,100%  0,50%  50% ,50% 50%,50% 50%}
    50%  {background-position:0  100%,100%  0,100% 100%,0   0  ,50% 50%}
    75%  {background-position:50% 50%,50% 50%,100% 100%,0   0  ,50% 50%}
    100% {background-position:50% 50%,50% 50%,50%  50% ,50% 50%,50% 50%}
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
}
