@layer components {
  .bg-pattern {
    @apply relative
            overflow-hidden;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 20%);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 20%);
  }
  .bg-pattern-inverted {
    @apply relative
            overflow-hidden;
    -webkit-clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0 0);
    clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0 0);
  }
  .bg-pattern-clip {
    @apply absolute
            inset-0;
  }
  .background-clipped {
    @apply relative;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 30%);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 30%);
  }
  .banner-odd-clip {
    @apply relative
            overflow-hidden;
    -webkit-clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 80%, 0 0);
  }
  .banner-even-clip {
    @apply relative
            overflow-hidden;
    -webkit-clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0 0);
    clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0 0);
  }
  @screen lg {
    .background-clipped {
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 50%);
      clip-path: polygon(100% 10%, 100% 100%, 0% 100%, 0 50%);
    }
  }
}
