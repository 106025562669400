@layer components {
  .blog-pagination li {
    @apply mb-0;
  }

  .blog-pagination li a {
    @apply p-2
            font-nandos
            text-md
            text-black;
  }

  .blog-pagination .active-page a {
    @apply text-pink-500;
  }
}
