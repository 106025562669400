@layer components {
  textarea::placeholder,
  input::placeholder,
  .datepicker-input::placeholder {
    @apply text-black
      text-xs
      font-noto;
  }
  textarea,
  input,
  .datepicker-input {
    @apply text-black
      font-noto
      leading-none;
  }

  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="text"],
  input[type="password"],
  select:focus,
  textarea {
    @apply text-base;
  }

  @screen lg {
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="text"],
    input[type="password"],
    select:focus,
    textarea {
      @apply text-sm;
    }
  }

  .input-light::placeholder,
  .datepicker-input-light::placeholder {
    @apply text-metal-100;
  }

  .search-input::placeholder {
    @apply text-metal-100;
  }

  .find-input::placeholder {
    @apply text-metal-100;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-text-field > div{
    @apply
      bg-white
    ;
  }

  .checkbox-field p {
    @apply
      text-xxs
      mb-0
    ;
  }

  .checkbox-field p a {
    @apply
      text-pink-500
      border-b-pink-500
    ;
  }
}
