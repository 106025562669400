@layer components {
  .menu {
    @apply bg-white
            bg-opacity-90
            h-auto
            overflow-y-scroll
            max-w-full
            absolute
            inset-x-0
            top-full
            duration-300
            transform
            ease-in-out
            transition-all
            shadow-lg
            pt-5
            pb-10
            rounded-b-xl;
  }

  .hamburger-line {
    @apply w-5
            h-2px
            rounded-full
            bg-red-500
            duration-300
            transform
            ease-in-out
            transition-all;
  }

  @screen lg {
    .menu {
      @apply bg-transparent
                overflow-y-visible
                flex-1
                flex
                items-stretch
                justify-end
                w-full
                relative
                shadow-none
                py-0
                rounded-none;
    }
  }
}
