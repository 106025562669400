@layer components {
  .content {
    @apply mt-6
            max-w-370px
            mx-auto;
  }

  .dark-content p,
  .dark-content span,
  .dark-content li {
    @apply text-sm
            leading-normal
            text-black;
  }
  .dark-content p:last-child {
    @apply mb-0;
  }

  .dark-content p a {
    @apply text-black;
  }

  .white-content p,
  .white-content span,
  .white-content li {
    @apply text-sm
            leading-normal
            text-white;
  }

  .white-content p:last-child {
    @apply mb-0;
  }

  .white-content p a {
    @apply text-white;
  }

  @screen md {
    .content {
      @apply max-w-750px;
    }
  }

  @screen lg {
    .content {
      @apply max-w-full;
    }
  }
}
