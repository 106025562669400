@layer components {
  .items-center .react-apple-signin-auth-btn {
    border: none;
  }

  .menu-item ul {
    @apply
      list-disc
      pl-4
    ;
  }

  .menu-item ol {
    @apply
      pl-4
    ;
  }

  .menu-item ul li,
  .menu-item ol li {
    @apply
      mb-1
    ;
  }
}
