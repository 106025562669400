@layer components {
  .react-player {
    @apply relative
          pb-6/10
          h-0
          w-full
          mx-0
          my-auto;
  }

  .react-player iframe,
  .react-player video {
    @apply absolute
          top-0
          left-0
          w-full
          h-full;
  }

  .react-player-loop {
    @apply relative
          h-0
          w-full
          m-auto
          overflow-hidden;
  }

  .react-player-loop iframe,
  .react-player-loop video {
    @apply absolute
          top-1/2
          left-1/2
          min-w-full
          min-h-full;
    width: auto !important;
    height: auto !important;
    transform: translate(-50%, -50%);
  }

  .square .react-player-loop iframe,
  .square .react-player-loop video {
    @apply absolute
          top-1/2
          left-1/2
          min-w-full-2x
          min-h-full;
    width: auto !important;
    height: auto !important;
    transform: translate(-50%, -50%);
  }

  @screen md {
    .react-player-loop {
      @apply pb-56pc;
    }
  }
}
