@layer components {
  html.modal-open {
    @apply overflow-hidden;
  }

  .modal {
    @apply left-1/2 
            top-1/2;
    transform: translate(-50%, -50%);
  }
}
