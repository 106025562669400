@layer components {
  .zoom-image {
    @apply transition-all
            duration-300
            transform
            scale-100;
  }
  .zoom-image:hover {
    @apply scale-110;
  }
}
