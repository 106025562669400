@layer utilities {
  @variants responsive {
    .bgr-img-large {
      max-width: 160px;
    }
    .bgr-img-small {
      max-width: 90px;
    }
    .top-105 {
      top: 105px;
    }
    .z-1 {
      z-index: 1;
    }
    .transform-v-center {
      top: 50% !important;
      transform: translateY(-50%);
    }
    .img-v-center > div {
      @apply transform-v-center;
    }
    .triangle-div {
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
    .triangle-div-mobile {
      clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 100%, 50% 100%, 0 50%);
    }
  }
  @media print {
    header,
    footer {
      display: none;
    }
    .hero-single-page,
    .btn-yellow-border,
    .back-to-news,
    .social-buttons-news,
    .single-news-location-bar,
    .flame-single-news {
      display: none;
    }
    .print-title,
    .date-single-news {
      text-align: center;
    }
    .logo-single-news {
      display: block;
    }
  }
}

@layer components {
  @media print {
    header,
    footer {
      display: none;
    }
    .hero-single-page,
    .btn-yellow-border,
    .back-to-news,
    .social-buttons-news,
    .single-news-location-bar,
    .flame-single-news {
      display: none;
    }
    .print-title,
    .date-single-news {
      text-align: center;
    }
    .logo-single-news {
      display: block;
    }
  }
}
