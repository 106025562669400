@layer components {
  .iframe-component iframe {
    @apply w-full
            h-full;
  }

  .iframe-promo iframe {
    @apply w-550px;
  }
}
