@import "tailwindcss/base";
@import "base.css";

@import "tailwindcss/components";
@import "components/datepicker.css";
@import "components/slick.css";
@import "components/sliders.css";
@import "components/typography.css";
@import "components/navigation.css";
@import "components/buttons.css";
@import "components/tabs.css";
@import "components/inputs.css";
@import "components/modals.css";
@import "components/clip-paths.css";
@import "components/image-mouse.css";
@import "components/video.css";
@import "components/iframe.css";
@import "components/pagination.css";
@import "components/list-items.css";
@import "components/animations.css";

@import "contentful/content.css";
@import "contentful/hero.css";

@import "tailwindcss/utilities";
@import "components/custom-utilities";
