.slick-track {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slick-dots {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.slick-dots li {
  list-style-type: none;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
  color: transparent;
  margin: 20px 3px;
}

.slick-dots li.slick-active {
  color: transparent;
}

.slick-dots li button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  border: none;
  background-color: #00afaa;
  color: transparent;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.slick-dots li.slick-active button {
  background-color: #e6007d;
  color: transparent;
}

/* Categories bar */
.categories-slick .slick-list,
.categories-slick .slick-track {
  overflow: visible;
}

.categories-slick .slick-list {
  padding: 0 64px;
}

/* Collection carousel */
.collection-slick .slick-list {
  padding: 32px 0;
  overflow: visible;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  height: 100%;
}

/* Collection carousel */
.collection-slick .slick-slide {
  margin: 0;
}

.collection-slick .slick-list .slick-slide > div {
  height: 100%;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

/* Tab navigation */
.tab-slick .slick-track {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.tab-slick .slick-list,
.tab-slick .slick-track {
  overflow: visible;
}

.tab-slick .tab-block {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .collection-slick .slick-list {
    overflow: hidden;
  }
}
