.css-4jnixx-MuiStack-root>.MuiTextField-root {
  min-width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #FFFFFF !important;
}