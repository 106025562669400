@layer components {
  .content,
  .banner,
  .dark-content {
    @apply mt-0;
  }

  .banner {
    @apply text-center;
  }

  .content h1,
  .content h2,
  .content h3,
  .banner h1,
  .banner h2,
  .banner h3,
  .dark-content h1,
  .dark-content h2,
  .dark-content h3 {
    @apply inline-block 
            p-0
            font-nandos
            mb-4
            text-9xl
            transform
            -rotate-2;
  }

  .content h4,
  .banner h4,
  .dark-content h4 {
    @apply inline-block 
            text-black
            p-0
            font-nandos
            mb-4
            text-xxl2
            transform
            -rotate-2;
  }
  .content h5,
  .banner h5,
  .dark-content h5 {
    @apply inline-block 
            text-black
            p-0
            font-noto
            mb-0
            text-xl;
  }

  .content h6,
  .content h6,
  .dark-content h6 {
    @apply inline-block 
            text-black
            p-0
            mb-0
            font-noto
            text-lg
            transform;
  }

  .content p,
  .dark-content p {
    @apply text-xs
            leading-normal
            mb-4;
  }

  .banner p {
    @apply text-xs
            leading-normal
            mb-4;
  }

  .content p:last-child,
  .banner p:last-child,
  .dark-content p:last-child {
    @apply mb-0;
  }

  .content p a,
  .banner p a {
    @apply leading-none
            text-pink-500
            border-b
            border-pink-500;
  }

  .content p a u,
  .banner p a u {
    @apply no-underline;
  }

  .dark-content p a {
    @apply leading-none
            text-black
            border-b
            border-black
            font-noto-bold;
  }

  .dark-content p a u {
    @apply no-underline;
  }

  .content ul,
  .dark-content ul,
  .banner ul {
    @apply p-0
      pl-6;
  }

  .content ul {
    @apply mb-4;
  }

  .content ul li,
  .dark-content ul li,
  .banner ul li {
    @apply list-item
            list-disc
            mb-0
            text-sm;
  }

  .content-tight p {
    @apply mb-0;
  }

  .table-heading {
    @apply mb-4;
  }

  .table-heading h6 {
    @apply mb-4;
  }

  .table-heading p {
    @apply pl-4
            mb-0;
  }

  .block-content p:first-child {
    @apply mb-4;
  }

  .list-items p {
    @apply mb-0;
  }

  .list-items a {
    @apply font-noto-bold;
  }

  .cta-content p a {
    @apply text-black
            font-noto-bold
            border-black;
  }

  .cta-content p a u {
    @apply no-underline;
  }

  .cta-content p a:hover {
    @apply text-pink-500
            border-pink-500;
  }

  .list-items p a {
    @apply text-black
            font-noto
            border-black;
  }

  .list-items p a u {
    @apply no-underline;
  }

  .periometer-intro h1,
  .periometer-intro h2 {
    @apply text-center
            relative
            pb-5
            max-w-370px
            m-auto
            mb-8
            block
            text-xxl2;
  }

  .periometer-intro h3 {
    @apply text-center
            relative
            pb-4
            text-md
            w-1/3
            float-left
            mt-8;
  }

  .modal-content h3,
  .modal-content h4,
  .modal-content h5,
  .modal-content h6 {
    @apply text-center
            relative
            pb-4
            text-md
            mt-8;
  }

  .periometer-intro h1:before,
  .periometer-intro h2:before,
  .modal-content h2:before {
    @apply absolute
          w-100px
          mx-auto
          bottom-0
          inset-x-0;
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTciIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCA5NyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuNjc4NzExIDEyLjczMzFWOS43OTIxNUwyNi45Nzg3IDYuMzMxMTVMNTEuNzIwNyA0LjYwMTE1TDk0LjI4NTcgMC4yNzUxNDZMOTYuMTg4NyAzLjU2MzE1TDUxLjM3NTcgOC40MDcxNUw0NC45NzI3IDkuNDQ1MTVDNDQuOTcyNyA5LjQ0NTE1IDM5LjI2MzcgOS43OTIxNSAzOC4zOTg3IDkuNzkyMTVDMzcuNTMyNyA5Ljc5MjE1IDMwLjc4NDcgMTAuNDgzMSAzMC43ODQ3IDEwLjQ4MzFMMjguMTg5NyA5Ljk2NDE1TDI0LjM4MzcgMTEuMDAzMUwyMC41NzY3IDExLjM0OTFDMjAuNTc2NyAxMS4zNDkxIDE3LjQ2MjcgMTAuODMwMSAxNi45NDM3IDEwLjgzMDFDMTYuNDI0NyAxMC44MzAxIDE1LjM4NTcgMTEuMTc2MSAxNC41MjA3IDExLjY5NTFDMTMuNjU1NyAxMi4yMTQxIDAuNjc4NzExIDEyLjczMzEgMC42Nzg3MTEgMTIuNzMzMVoiIGZpbGw9ImJsYWNrIi8+PC9zdmc+");
  }

  .periometer-intro h3:before,
  .modal-content h3:before,
  .modal-content h4:before,
  .modal-content h5:before,
  .modal-content h6:before {
    @apply absolute
          w-50px
          mx-auto
          bottom-0
          inset-x-0;
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDUzIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjAzNjEzMjggNi40ODEyVjUuMDY0NzZMMTQuMzU1MSAzLjM5Nzg4TDI3LjgyNTcgMi41NjQ2OEw1MSAwLjQ4MTIwMUw1Mi4wMzYxIDIuMDY0NzZMMjcuNjM3OSA0LjM5NzcyTDI0LjE1MTggNC44OTc2NEMyNC4xNTE4IDQuODk3NjQgMjEuMDQzNiA1LjA2NDc2IDIwLjU3MjYgNS4wNjQ3NkMyMC4xMDExIDUuMDY0NzYgMTYuNDI3MiA1LjM5NzU2IDE2LjQyNzIgNS4zOTc1NkwxNS4wMTQ0IDUuMTQ3NkwxMi45NDIyIDUuNjQ4TDEwLjg2OTUgNS44MTQ2NEMxMC44Njk1IDUuODE0NjQgOS4xNzQxMSA1LjU2NDY4IDguODkxNTQgNS41NjQ2OEM4LjYwODk3IDUuNTY0NjggOC4wNDMyOSA1LjczMTMyIDcuNTcyMzUgNS45ODEyOEM3LjEwMTQgNi4yMzEyNCAwLjAzNjEzMjggNi40ODEyIDAuMDM2MTMyOCA2LjQ4MTJaIiBmaWxsPSJibGFjayIvPjwvc3ZnPg==");
  }

  .periometer-intro p,
  .modal-content p {
    @apply mb-2
          text-xs;
  }

  .periometer-intro ul,
  .modal-content ul {
    @apply w-2/3
          p-0
          float-right
          mt-7;
  }

  .periometer-intro ul li,
  .modal-content ul li {
    @apply mb-2
          text-xs
          list-none;
  }

  .periometer-intro p,
  .modal-content p {
    @apply mb-2
          text-xs
          tracking-normal;
  }

  .content p b,
  .dark-content p b,
  .banner p b,
  .block-content p b,
  .table-heading p b,
  .list-items p b,
  .cta-content p b {
    @apply font-noto-bold;
  }

  .content-normal p {
    @apply text-base;
  }

  .content-18 p {
    @apply text-body;
  }

  .content-20 p {
    @apply text-md;
  }

  .content-24 p {
    @apply text-xl;
  }

  .content hr,
  .banner hr,
  .dark-content hr {
    @apply -mt-4
      mb-0
      border-none;
  }

  .chillis-points-text {
    @apply absolute inset-0 text-white m-auto flex items-center justify-center font-noto-bold left-2;
    font-size: 3.95vw;
  }

  .remove-margin-content h1,
  .remove-margin-content h2,
  .remove-margin-content h3,
  .remove-margin-content h4,
  .remove-margin-content h5,
  .remove-margin-content h6,
  .remove-margin-content p {
    @apply mb-0;
  }

  @screen md {
    .content h1,
    .content h2,
    .content h3,
    .content h4 {
      @apply text-6xl;
    }
    .periometer-intro h1,
    .periometer-intro h2 {
      @apply text-xxl2;
    }

    .periometer-intro h3 {
      @apply text-md;
    }

    .chillis-points-text {
      font-size: 3vw;
    }
  }

  @screen lg {
    .chillis-points-text {
      @apply left-4;
      font-size: 2.3vw;
    }
  }
}
