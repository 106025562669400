@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/Noto Sans/NotoSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nandos Hand';
  src: url('../static/fonts/Nandos Hand/NandosHand-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0px;
  padding: 0px;
}

body,
body.contrast-red {
  color: #414141;
  font-family: "Noto Sans" !important;
  height: 100%;
  background-color: #f9f7f1 !important;
}

body a {
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  -webkit-transition: .3s;
  color: #414141;
  text-decoration: none !important;
  transition: .3s;
}

body a:hover {
  color: #c55330; 
}

/* comon display and float and position */

.disp-table {
  display: table;
  width: 100%;
}

.pos-relative {
  position: relative;
}


/* */


/* Common padding and Margin */

.padt40 {
  padding-top: 40px;
}

.padb20 {
  padding-bottom: 20px;
}

.padnb120 {
  padding-bottom: 120px !important;
}


/*  */


/* Index page */
.iframe-header {
  padding: 64px 0 0 0;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.iframe-header h1 {
  font-family: 'Nandos Hand' !important;
  font-size: 33px !important;
}

.navbar-div {
  display: inline-block;
  font-size: 23pt;
  padding: 30px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100vw;
}

.navbar-div .popup {
  color: #900027;
  display: inline-block;
  font-weight: 400;
  text-decoration: none;
  vertical-align: middle;
  letter-spacing: 1px;
}

.navbar-div span+span:before {
  color: #929292;
  content: 'OR';
  display: block;
  line-height: 32px;
  display: inline-block;
  font-size: 13px;
  margin: 0 20px 0 10px;
  background-color: #e7ebeb;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 15px 9px;
  line-height: 3px;
}

.seprator {
  display: inline-block;
  height: 40px;
  margin: -5px 20px 0 20px;
  position: relative;
  vertical-align: middle;
  width: 4px;
}

.seprator .or-text {
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #e7ebeb;
  color: #929292;
  font-size: 14px;
  height: 30px;
  left: -12px;
  line-height: 30px;
  padding: 0 8px;
  position: absolute;
  top: 7px;
}


/* Index page end */


/* anchor tags property */

.forgot-div a,
.signup-form form a,
.info-div a {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 5px 0 10px;
  position: relative;
  text-align: right;
  text-decoration: none;
}

.forgot-div a:before,
.signup-form form a:before,
.info-div a:before {
  -webkit-transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  background-color: #c55330;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
}

.forgot-div a:hover:before,
.signup-form form a:hover:before,
.info-div a:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  visibility: visible;
}


/* anchor tag property end */


/* login Sign-up Page */

.iframe-container {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
}

.iframe-container .password-form {
  width: 585px;
  margin: 0 auto;
  padding: 0 0 20px;
}

.iframe-container .signup-form,
.iframe-container .login-form {
  width: 585px;
  margin: 0 auto;
  padding: 40px 0 20px;
}

@media screen and (max-width: 480px) {
  .iframe-container .signup-form,
  .iframe-container .login-form,
  .iframe-container .password-form {
    width: 86%;
    margin: 0 7%;
  }
}

.iframe-container form {
  display: inline-block;
  width: 265px;
  padding-right: 15px;
  vertical-align: middle;
}

.iframe-container .password-form form {
  padding-right: 0;
  width: 320px;
}

@media screen and (max-width: 480px) {
  .iframe-container form {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.form-hint {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 4px 0 10px;
  text-align: left;
  width: 100%;
  display: block;
}

.iframe-container form input,
.iframe-container form textarea {
  background-color: #ffffff;
  border: solid 1px #a3a3a3;
  box-sizing: border-box;
  color: #414141;
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  height: 41px;
  line-height: 41px;
  outline: none;
  padding: 5px;
  width: 100%;
}

.iframe-container form label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin: 5px 0 0;
  text-align: left;
}

.iframe-container form .error-messages {
  -webkit-font-smoothing: antialiased;
  color: #c55330;
  display: block;
  letter-spacing: 1px;
  line-height: 18px;
  width: 100%;
}

.iframe-container form .error-messages h2 {
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 10px;
  text-align: left;
}

.iframe-container form .error-messages li {
  font-size: 14px;
  line-height: 14px;
  list-style: none;
  padding-bottom: 5px;
  text-align: left;
}

.iframe-container form .btn-submit {
  background-color: #282828;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  height: 40px;
  letter-spacing: 1px;
  line-height: 40px;
  padding: 0px;
  text-transform: uppercase;
  width: 100%;
}

.iframe-container form .btn-submit:hover {
  -moz-transition: all 0.3s 0s;
  -o-transition: all 0.3s 0s;
  -webkit-transition: all 0.3s 0s;
  transition: all 0.3s 0s;
  background-color: #c55330;
}

.iframe-container form .submit-div input {
  background-color: #FFCD41;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  padding-top: 14px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: "Noto Sans" !important;
  font-weight: bold !important;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  z-index: 0;
  transition: all;
  transition-duration: .300s;
  border: none;
  color: #000000;
  width: 100%;
  margin-top: 20px;
}

.container-fluid input.btn {
  background-color: #FFCD41;
}

.container-fluid input.btn:hover {
  background-color: #EB3F55;
}

.container-fluid .btn:hover {
  color: #FFFFFF;
}

.iframe-container form .submit-div input:hover {
  -moz-transition: all 0.3s 0s;
  -o-transition: all 0.3s 0s;
  -webkit-transition: all 0.3s 0s;
  transition: all 0.3s 0s;
  background-color: #EB3F55;
  color: #FFFFFF;
}

.iframe-container .facebook-login-div {
  display: inline-block;
  height: 100%;
  min-height: 567px;
  padding-left: 49px;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 250px;
}

@media screen and (max-width: 480px) {
  .iframe-container .facebook-login-div {
    margin: 0;
    min-height: auto;
    padding: 0;
    width: 100%;
  }
}

.iframe-container .facebook-login-div:before {
  border-left: solid 2px #a3a3a3;
  content: "";
  min-height: 567px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: 1;
  z-index: 22;
}

@media screen and (max-width: 480px) {
  .iframe-container .facebook-login-div:before {
    height: 2px;
    width: 100%;
    border-left: none;
    border-top: solid 2px #a3a3a3;
    left: -20px;
    margin: 0 7%;
    min-height: 2px;
    min-width: 276px;
    padding: 0;
    top: 33px;
    width: 246px;
  }
}

.iframe-container .facebook-link {
  width: 250px;
  display: inline-block;
  vertical-align: top;
  padding: 25px 0 0;
  font-weight: 300;
}

@media screen and (max-width: 480px) {
  .iframe-container .facebook-link {
    width: 100%;
    padding: 60px 0 0;
  }
}

.facebook-label {
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #edf2f2;
  border: solid 2px #a3a3a3;
  color: #a3a3a3;
  display: inline-block;
  font-size: 14px;
  height: 30px;
  left: -16px;
  line-height: 30px;
  margin: 0 15px;
  position: absolute;
  text-align: center;
  top: 44%;
  vertical-align: middle;
  width: 30px;
  z-index: 66;
}

@media screen and (max-width: 480px) {
  .facebook-label {
    left: 40%;
    top: 15px;
  }
}


/* Social Login */

.btn-fb,
.facebook-link {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
}

.btn-fb a,
.facebook-link a {
  background-color: #436c99;
  color: #fff;
  color: #fff;
  display: inline-block;
  height: 100%;
  height: 40px;
  letter-spacing: 1px;
  line-height: 40px;
  margin-bottom: 14px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.btn-fb a:before,
.facebook-link a:before {
  content: url("https://cdn.punchh.com/white-label-basic-images/icon-fb.jpg") no-repeat;
  display: inline-block;
  height: 43px;
  margin: 0 10px;
  vertical-align: middle;
  width: 40px;
}

.btn-fb a:hover,
.facebook-link a:hover {
  color: #fff;
}


/* Social Login end */


/* login forum */

.login-form {
  position: relative;
}

.login-form form {
  padding-top: 20px;
  padding-bottom: 20px;
}

.login-form .facebook-login-div {
  min-height: 230px;
}

@media screen and (max-width: 480px) {
  .login-form .facebook-login-div {
    min-height: auto;
  }
}

.login-form .facebook-login-div:before {
  min-height: 230px;
}

@media screen and (max-width: 480px) {
  .login-form .facebook-login-div:before {
    min-height: auto;
  }
}

.login-form .facebook-login-div .facebook-link {
  padding-top: 95px;
}

.login-form .submit-div {
  margin-top: 31px;
}

.forgot-div {
  top: 190px;
  position: absolute;
  text-align: right;
  width: 265px;
}

@media screen and (max-width: 480px) {
  .forgot-div {
    width: 100%;
  }
}


/* login Forum */


/* Alert - Message */

.alert-message {
  color: #c55330;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
}

.alert-message strong {
  font-weight: 300;
}


/* alert-message end */


/* password-form recovery */

.password-form .header-div {
  font-size: 20px;
  font-family: "Noto Sans";
  font-weight: 400;
  letter-spacing: 0;
  text-transform: uppercase;
  padding: 30px 0;
}

.password-form .submit-div {
  margin-top: 20px;
}

.password-form .submit-div input {
  font-size: 14px !important;
}


/* password-form recovery end */


/* user-dahboard */

.user-dahboard {
  text-align: center;
}

.user-dahboard .iframe-header,
.user-dahboard .iframe-container {
  display: inline-block;
  vertical-align: top;
  min-height: 298px;
  border: solid 1px #a3a3a3;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  text-align: left;
  margin: 30px 0;
}

.user-dahboard .iframe-header {
  background-color: #fff;
  padding: 0;
  width: 238px;
}

.user-dahboard .iframe-header .header {
  display: none;
}

.user-dahboard .iframe-header .navbar-div {
  width: 100%;
  padding: 0;
}

.user-dahboard .iframe-header .navbar-div span a {
  border-top: solid 1px #a3a3a3;
  display: block;
  font-size: 14px;
  line-height: 14px;
  padding: 17px 0;
  text-transform: none;
  letter-spacing: 1px;
}

.user-dahboard .iframe-header .navbar-div span.account-link a {
  border: none;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}

.user-dahboard .iframe-header .navbar-div span.signout-link {
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

.user-dahboard .iframe-header .navbar-div span.signout-link a {
  background-color: #c55330;
  border: none;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

.user-dahboard .iframe-header .navbar-div span+span:before {
  display: none;
}

.user-dahboard .iframe-container {
  background-color: #fafafa;
  width: 638px;
  padding: 15px;
  margin-left: 50px;
}

.user-dahboard .alert-message {
  width: auto;
}

.user-dahboard .current-checkins-div {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.user-dahboard .checkin-form form {
  width: 100%;
}

.user-dahboard .barcode-digit,
.user-dahboard .barcode-example {
  color: #a3a3a3;
  display: block;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  letter-spacing: 1px;
}

.user-dahboard textarea {
  font-weight: 300;
  letter-spacing: 1px;
  min-height: 100px;
  letter-spacing: 1px;
}

.user-dahboard .feedback-share-div {
  display: block;
}

.user-dahboard .feedback-share-div input {
  width: auto;
  vertical-align: middle;
}

.user-dahboard .feedback-share-div label {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  font-weight: 300;
  font-size: 13px;
  text-transform: none;
}

.user-dahboard .info-div,
.user-dahboard .expiry-warning {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 10px 0;
}

.user-dahboard .info-div a,
.user-dahboard .expiry-warning a {
  float: none !important;
  font-size: 16px;
  font-weight: 400;
}

.user-dahboard .submit-div {
  width: 280px;
  margin: 0 auto;
  padding: 20px 0;
}

.user-dahboard .signup-form form,
.user-dahboard .redemption-form form {
  width: 100%;
}

.user-dahboard .barcode-div {
  padding-bottom: 15px;
}

.user-dahboard .expiry-warning {
  text-align: center;
}

.user-dahboard .alert-message {
  position: absolute;
}


/* user-dahboard end */


/*# sourceMappingURL=iframe-style.css.map */

.ecrm-registration-div select {
  background-color: #edf2f2;
  border: solid 1px #a3a3a3;
  box-sizing: border-box;
  color: #414141;
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  outline: none;
  padding: 5px;
  width: 32%;
}

.ecrm-registration-div select#location_id {
  width: 100% !important;
}

.ecrm-registration-div input[type="checkbox"] {
  display: inline-block;
  height: auto;
  margin-top: 10px;
  vertical-align: top;
  width: auto !important;
}

.ecrm-registration-div form label.middle {
  display: inline-block;
  vertical-align: top;
  width: 90%;
  text-transform: none;
}

.ecrm-registration-div .submit-div {
  margin-top: 15px;
  margin-bottom: 25px;
}

.ecrm-registration-div label.required:before {
  content: '*';
  color: #ff0000;
  display: inline-block;
  margin-right: 3px;
}

select#user_fav_location_id {
  background-color: #edf2f2;
  border: solid 1px #a3a3a3;
  box-sizing: border-box;
  color: #414141;
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  outline: none;
  padding: 5px;
  width: 100%;
  height: 41px;
}
select#user_fav_location_id:focus {
 /* outline: 2px solid #0087d9;*/
 outline: -webkit-focus-ring-color auto 5px;
}

select#location_id:focus {
 /* outline: 2px solid #0087d9;*/
 outline: -webkit-focus-ring-color auto 5px;
}

select[disabled] {
  color: #666;
  background: #fff;
  cursor: not-allowed;
}

.text-link {
  color: #0087d9;
}

.text-link::before {
  background: #8BBCED;
}
.show-location {
  display: inline-block !important;
  text-align: left;
  vertical-align: middle;
  width: auto;
}
#all-location {
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  padding: 0;
  margin: 0;
  font-size: 14px;
  margin-top: 5px;
  font-weight: lighter !important;
  text-decoration: underline !important;
}

#fav-location-id-hint {
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  font-weight: normal;
  float: left;
  margin-top: 20px;
}

/* Forms */
.contrast-red {
  color: #414141;
  font-family: "Noto Sans" !important;
  height: 100%;
  background-image: none !important;
  background-color: #FFFFFF !important;
  font-family: 'Noto Sans';
  font-size: 14px !important;
}

.contrast-red .alert-dismissable {
  margin-bottom: 32px;
  font-size: 16px;
}

.contrast-red .container {
  padding: 30px 16px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.contrast-red h1 {
  font-family: 'Nandos Hand' !important;
  font-size: 33px !important;
  text-align: center;
}

.contrast-red .alert {
  text-align: center;
}

.contrast-red .account-wall {
  text-align: center;
}

.contrast-red .account-wall .profile-img {
  height: auto;
  margin-top: 16px;
}

.contrast-red .account-wall form {
  width: 100%;
}

.contrast-red .account-wall form ul li {
  list-style-type: none;
  color: #c55330;
}

.contrast-red .account-wall .form-control {
  width: calc(100% - 34px);
  background-color: #FFFFFF;
  padding: 8px 16px;
  border: 1px solid #414141;
  font-size: 16px;
  margin-top: 16px;
  background-image: none !important;
}

.contrast-red .account-wall button {
  background-color: #FFCD41 !important;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  padding-top: 14px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: "Noto Sans" !important;
  font-weight: bold !important;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  z-index: 0;
  transition: all;
  transition-duration: .300s;
  border: none;
  color: #000000;
  width: 100%;
  margin-top: 20px;
}

.contrast-red .account-wall .text-muted {
  display: block;
  text-align: left;
  margin-top: 2px;
}

.contrast-red .container .new-account {
  width: auto;
  margin: 0 auto;
  margin-top: 16px;
  color: #000000;
  font-size: 16px;
  display: block;
  text-align: center;
}

.contrast-red .account-wall .need-help {
  width: 100%;
  color: #000000;
  font-size: 16px;
  margin-top: 16px;
  display: block;
  text-align: center;
}

.contrast-red .facebook-login-div {
  width: 100%;
  margin: 0 auto;
  max-width: 350px;
  margin-top: 16px;
}

.contrast-red .facebook-login-div .facebook-label {
  top: 0;
  position: relative;
  margin: 0 auto;
  margin-bottom: 16px;
  margin-left: 24px;
}

/* password-form recovery */
 
.password-form .header-div {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 30px 0;
}
 
.password-form .submit-div {
  margin-top: 20px;
}
 
.password-form .submit-div input {
  font-size: 14px !important;
}
/* password-form recovery end */

/* Custom Styles 45RPM */
.container-fluid {
  max-width: 500px;
  margin: 0 auto;
  padding: 64px 32px;
}

.container-fluid .btn {
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  padding-top: 14px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: "Noto Sans" !important;
  font-weight: bold !important;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  z-index: 0;
  transition: all;
  transition-duration: .300s;
  border: none;
  color: #000000;
  background-color: transparent;
  width: 100%;
  margin-top: 20px;
}

.container-fluid .btn:before {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: '';
  -webkit-clip-path: polygon(17px 0,100% 0,100% 100%,0 100%,0 0,4px 0,10px 10px);
  clip-path: polygon(17px 0,100% 0,100% 100%,0 100%,0 0,4px 0,10px 10px);
  background-color: #FFCD41;
}

.container-fluid .btn:hover:before {
  background-color: #EB3F55;
}

.container-fluid .btn:after {
  position: absolute;
  left: 2px;
  top: -8px;
  transition: all;
  transition-duration: .300s;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjAwMDUgMTUuODk4OUwyMCAxLjg5ODkzSDJMMTEuMDAwNSAxNS44OTg5WiIgZmlsbD0iI0U2MDA3RCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+');
}

.container-fluid .btn:hover:before {
  position: -1px;
}

.container-fluid img {
  width: 180px;
  margin: auto;
  text-align: center;
  display: block;
  margin-bottom: 16px;
}

.container-fluid h1 {
  text-align: center;
  margin-bottom: 16px;
  font-family: "Nandos Hand" !important;
}

.container-fluid h3,
.container-fluid h4 {
  text-align: center;
  margin-bottom: 16px;
  font-family: "Noto Sans" !important;
  font-weight: bold !important;
}

.container-fluid .form-inputs {
  margin-bottom: 8px;
}

.container-fluid .form-inputs .form-group {
  margin-top: 20px;
}

.container-fluid .form-inputs .form-group span {
  margin-top: 10px;
  display: block;
}

.container-fluid .form-inputs label {
  font-family: "Noto Sans" !important;
  margin-bottom: 8px;
  display: block;
}

.container-fluid .form-inputs input {
  border: none;
  padding: 8px;
  cursor: auto;
  font-size: 16px;
  width: 97%;
  display: inline-block;
}

.grecaptcha-badge {
  z-index: 1 !important;
}

.css-160unip > .MuiTextField-root {
  width: 100%;
}

button.Mui-selected {
  color: #ffffff !important;
}

.MuiListSubheader-sticky {
  margin: 0 !important;
  line-height: 1.75rem !important;
}

.MuiMenu-list {
  padding: 0 !important;
  color: #4B4B4B !important;
}

.MuiListSubheader-root {
  border-top: 1px solid #C9C9C9;
  color: #4B4B4B !important;
  padding-top: 5px !important;
  padding-bottom: 2px !important;
}

.Mui-selected {
  color: #ffffff !important;
  background-color: #E6007D !important;
}