@layer components {
  .tab {
    @apply relative
        inline-block
        text-center
        leading-none
        px-2.5
        pt-3.5
        pb-11px
        text-xs
        font-noto-bold
        cursor-pointer
        outline-none
        uppercase
        z-0
        bg-metal-200
        text-black;
  }

  .tab-active {
    @apply bg-pink-500
          text-white;
  }
  .tab-news {
    @apply rounded-full
        px-2.5
        py-3px
        mr-13px
        text-black;
  }
  .tab-news-active {
    @apply font-bold
        text-white
        bg-ocean-500
        font-noto-bold;
  }

  .tab-block {
    flex: 0 0 auto;
    max-width: 100px;
  }

  @screen md {
    .tab-block {
      flex: none;
      max-width: none;
    }
  }
}
