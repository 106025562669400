@layer base {
  html {
    @apply overflow-x-hidden;
  }

  body {
    @apply text-black text-sm font-noto leading-relaxed bg-white bg-none;
  }

  p,
  li {
    @apply mb-4;
  }

  p a {
    @apply leading-none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-8;
  }

  h1 {
    @apply text-6xl leading-tight font-nandos;
  }

  h2 {
    @apply text-4xl normal-case leading-tight font-noto;
  }

  h3 {
    @apply leading-tight text-xxl2 font-noto;
  }

  h4 {
    @apply text-xl2 leading-normal font-noto;
  }

  h5 {
    @apply leading-tight text-lg font-noto;
  }

  h6 {
    @apply leading-normal text-body font-noto font-semibold;
  }

  h1 strong {
    @apply font-normal;
  }

  figure {
    @apply flex items-center;
  }

  figure img {
    @apply w-full;
  }

  @screen sm {
    h1 {
      @apply text-8xl;
    }
  }
}

@layer components {
  body {
    text-rendering: geometricPrecision;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  /* .line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
}
