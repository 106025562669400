@layer components {
  /* Add font-face from each font-family bellow this line */

  /* Font Nandos Hand */
  @font-face {
    font-family: "Nandos Hand";
    src: url("../../../public/static/fonts/Nandos Hand/NandosHand-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  /* Font Barlow */
  @font-face {
    font-family: 'Barlow Medium';
    src: url('../../../public/static/fonts/Barlow/Barlow-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  /* Font Nato Sans Regular */
  @font-face {
    font-family: "Noto Sans";
    src: url("../../../public/static/fonts/Noto Sans/NotoSans-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  p {
    @apply mb-8;
  }

  p.alert-text,
  p.alert-text a,
  p.alert-text a:hover {
    @apply text-white no-underline;
  }

  .font-noto-bold {
    @apply
      font-noto
      font-semibold
    ;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @apply mb-8;
  }

  .h1 {
    @apply text-6xl leading-tight font-nandos;
  }

  .h2 {
    @apply text-4xl normal-case leading-tight font-noto font-semibold;
  }

  .h3 {
    @apply leading-tight text-xxl2 font-noto font-semibold;
  }

  .h4 {
    @apply text-xl2 leading-normal font-noto font-semibold;
  }

  .h5 {
    @apply leading-tight text-lg font-noto font-semibold;
  }

  .h6 {
    @apply leading-normal text-body font-noto font-semibold;
  }

  .h1 strong {
    @apply font-normal;
  }

  .checkbox-content p:last-child {
    @apply mb-0;
  }

  @screen sm {
    .h1 {
      @apply text-8xl;
    }
  }

  @screen md {
    .h2 {
      @apply text-3xl;
    }
  }
}
